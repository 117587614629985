.counterPlaceholder {
  --heading-line-height: var(--ui-font-heading-5xl-line-height-sm);

  @media (min-width: token(breakpoints.minMd)) {
    --heading-line-height: var(--ui-font-heading-5xl-line-height-md);
  }

  @media (min-width: token(breakpoints.minLg)) {
    --heading-line-height: var(--ui-font-heading-5xl-line-height-lg);
  }

  height: var(--heading-line-height);
}
